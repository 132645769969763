import React from 'react';

import 'video-react/styles/scss/video-react.scss';

import { Player, Shortcut, ControlBar, VolumeMenuButton, LoadingSpinner,BigPlayButton, FullscreenToggle } from 'video-react';
import HLSSource from './HLSSource';


class MyPlayer extends React.Component{
	constructor (props){
		super(props);

		this.state = {
			isLoaded: false,
			film : '',
			synopsis: '',
			keyCode: ''
		};

		this.newShortcuts = [
			// Basé sur la zapette de la firestick tv
			{
				keyCode: 39, // Right arrow
				handle: (player, actions) => {
					actions.forward(5, {
						action: 'forward-5',
						source: 'shortcut'
					}); // Go forward 5 seconds
				}
			},
			{
				keyCode: 38, // Commande Hisense
				handle: (player, actions) => {
					actions.forward(10, {
						action: 'forward-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
				}
			},
			{
				keyCode: 228, // l
				handle: (player, actions) => {
					actions.forward(10, {
						action: 'forward-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
				}
			},
			{
				keyCode: 37, // Left arrow
				handle: (player, actions) => {
					actions.replay(5, {
						action: 'replay-5',
						source: 'shortcut'
					}); // Go forward 5 seconds
				}
			},
			{
				keyCode: 227, // l
				handle: (player, actions) => {
					actions.replay(10, {
						action: 'replay-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
				}
			},
			{
				keyCode: 179, // play/pause
				handle: (player, actions) => {
					if (player.paused) {
						actions.play({
							action: 'play',
							source: 'shortcut'
						});
					} else {
						actions.pause({
							action: 'pause',
							source: 'shortcut'
						});
					}
				}
			},
			{
				keyCode: 13, // keyboard enter
				handle: (player, actions) => {
					if (player.paused) {
						actions.play({
						  action: 'play',
						  source: 'shortcut'
						});
						//player.paused=true;
					} else {
						actions.pause({
							action: 'pause',
							source: 'shortcut'
						});
						//player.paused=false;
					}
				}
			},
			//____________TEST____________
			{
				keyCode: 177, // previous //TODO work on tv ???
				handle: (player, actions) => {
					actions.replay(10, {
						action: 'replay-10',
						source: 'shortcut'
					}); // Go backward 10 seconds
				}
			},
			{
				keyCode: 176, // next //TODO work on tv ???
				handle: (player, actions) => {
					actions.forward(10, {
						action: 'forward-10',
						source: 'shortcut'
					}); // Go forward 10 seconds
				}
			},

		];
	  
	}
	
	componentDidMount(){
		this.setState({film : localStorage.getItem("Film") });
		this.mainPlayer.focus(); // DEBUG KEY CODE
		this.player.handleFocus(); // VERSION FIRESTICK / TV OnLine Prod
		localStorage.removeItem("Film");
        localStorage.removeItem("FilmVideo");
        localStorage.removeItem("FilmHls");

		//console.log("this.player : ",this.player);
	}


	render(){
		const debug = <div style={{position:"absolute", top:10, left:10, color:'#fff', zIndex:999}}>keyCode : {this.state.keyCode}</div >

		return(
			<div 
				style={{position:"relative",width:window.innerWidth, height:window.innerHeight }}
				ref={(ref) => this.mainPlayer = ref}
				onKeyDown={(key) => { this.setState({keyCode: key.keyCode}); console.log(">>>>>>>>key : ",key ) }}
			>

				<Player 
					ref={(player) => { this.player = player }}
					fluid={false}
					videoId='Player1'
					autoPlay={true}
					preload='auto'
					width={window.innerWidth}
					height={window.innerHeight}
				>
					<div style={{position:"absolute", top:10, left:10, color:'#fff', zIndex:999}}>keyCode : {this.state.keyCode}</div >
					<LoadingSpinner />
					<HLSSource
						isVideoChild
						src={this.state.film}
					/>
					<BigPlayButton position="center" />
					<ControlBar autoHide={true} >
						<VolumeMenuButton vertical />
						<FullscreenToggle disabled />
					</ControlBar>
					<Shortcut clickable={false} shortcuts={this.newShortcuts} />
				</Player>
			</div>
		)
	}
}

export default MyPlayer;