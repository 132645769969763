export default {
	V_SERVEUR: ".4.2",


	HAUTEUR : window.innerHeight,
	LARGEUR : window.innerWidth,
	FETCH_VUSER: "FETCH_VUSER",

	FETCH_VACCUEIL_V2: "FETCH_VACCUEIL_V2",
	FETCH_VACCUEIL_CHASSE_V2: "FETCH_VACCUEIL_CHASSE_V2",
	FETCH_VACCUEIL_PECHE_V2: "FETCH_VACCUEIL_PECHE_V2",
	FETCH_VACCUEIL_CHASSESOUSMARINE_V2: "FETCH_VACCUEIL_CHASSESOUSMARINE_V2",

	FETCH_VACCUEIL: "FETCH_VACCUEIL", 
	FETCH_VACCUEIL_CHASSE: "FETCH_VACCUEIL_CHASSE",
	FETCH_VACCUEIL_PECHE: "FETCH_VACCUEIL_PECHE",
	FETCH_VACCUEIL_CHASSESOUSMARINE: "FETCH_VACCUEIL_CHASSESOUSMARINE",

	FETCH_VCHASSE: "FETCH_VCHASSE",
	FETCH_VCHASSE_RAND: "FETCH_VCHASSE_RAND",
	FETCH_VPECHE: "FETCH_VPECHE",
	FETCH_VPECHE_RAND: "FETCH_VPECHE_RAND",
	FETCH_VPECHESOUSMARINE: "FETCH_VPECHESOUSMARINE",
	FETCH_VPECHESOUSMARINE_RAND: "FETCH_VPECHESOUSMARINE_RAND",

	FETCH_UPCOMING: "FETCH_UPCOMING",
	FETCH_POPULAR: "FETCH_POPULAR",
	FETCH_TOPRATED: "FETCH_TOPRATED",
	FETCH_NOWPLAYING: "FETCH_NOWPLAYING",

	FETCH_SEARCH: "FETCH_SEARCH",
	FETCH_THEMOVIE: "FETCH_THEMOVIE",
	FETCH_CASTLIST: "FETCH_CASTLIST",

	CLEAR_THE_MOVIE: "CLEAR_THE_MOVIE",
	CLEAR_SEARCH_RESULT: "CLEAR_SEARCH_RESULT",

	FETCH_TMDBAPI_ERROR: "FETCH_TMDBAPI_ERROR",


	FETCH_GETVIDEOAPI_ERROR: "FETCH_GETVIDEOAPI_ERROR",
	
};
