import React from 'react';
import ReactTV from 'react-tv';

import { Focusable } from 'react-key-navigation'

import { VerticalList, HorizontalList } from 'react-key-navigation'

import './MonCompte.css'

const mData = {
	is_Guest:'',
	NoCli:'',
}
export default class MonCompte extends React.Component {
	constructor() {
		super();

		this.state = {
			active: false,
			isLoged: false,
			isLoaded: false,
			search: '',
		};
	}


	onBlur() {
		this.setState({active: false});
	}

	onFocus() {
		this.setState({active: true});
	}

	onEnterDown(event, navigation) {
		console.log('enter pressed');
		console.log('Search navigation : ', navigation)
//		navigation.forceFocus('sidebar');
	}

	componentDidMount(){

		var mesData = JSON.parse(localStorage.getItem("LOGIN_TOKEN"));
		console.log("mesData : ",mesData.NoCli)
		if(!mesData) mesData = mData;


		if(mesData.is_Guest==="" && mesData.NoCli===''){
			this.setState({ isLoged: false });
			window.location.assign("/");
		}else{
			this.setState({ isLoged: true })
		}

		this.setState({isLoaded: false});
		
//		this.setState({isLoaded: true});
//		this.props.parent.state.navig.forceFocus(this.props.parent.state.Categ)
	}

	render() {
		return (
			<div className={"mainbox" + (this.state.active ? ' focused' : '')} >
				<VerticalList focusId="user"  id="user" onBlur={() => this.onBlur()}>
					
					<div style={{textAlign:'center',color:'#fff'}} >MonCompte Z3</div>

				</VerticalList>
			</div>
		);
	}


}
