import React from 'react';

import SideBar from './SideBar';
import List from './List';
//import Search from './Search';
//import Slider from './Slider';

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';


import axios from 'axios';
import constants from '../../constants';


import VideoPlayer from '@cybergab/gab-react-video-js-player';

import { VerticalList, HorizontalList } from 'react-key-navigation'
import './Zone300TV.css';

import MonZ3 from './MonZ3';
import Chasse from './Chasse';
import Peche from './Peche';
import Search from './Search';
import MonCompte from './MonCompte';

const mData = {
	is_Guest:'',
	NoCli:'',
}

class Zone300TV extends React.Component {
	constructor(){
		super()
		this.state = {
			isLoged: false,
			active: null,
			Categ: '',
			isLoaded: false,
			uriVideo: '',
			CategLoaded : false,
			navig: null,
		}
		this.vCateg = [];
		this.vFilm = [];
//		this.lists = ["Title 1", "Title 2", "Title 3", "Title 4"];
		
	}
	changeFocusTo(index) {
		this.setState({active: index});
	  }
	
	onBlurLists() {
		this.setState({active: null});
	}
	
	
	componentDidMount(){
	  
		var mesData = JSON.parse(localStorage.getItem("LOGIN_TOKEN"));
		//console.log("mesData : ",mesData.NoCli)
		if(!mesData) mesData = mData;


		if(mesData.is_Guest==="" && mesData.NoCli===''){
			this.setState({ isLoged: false });
			window.location.assign("/");
		}else{
			this.setState({ isLoged: true })
		}

		var ou = localStorage.getItem("menuKey");
		if(ou !== "" && ou !== null){
			this.setState({Categ: ou});
//			console.log("Zone300TV did mount manavOK : ",this.state, " menuKey : ",ou);

		}else{
			this.setState({Categ: 'monZ3'});
		}
		/*
		var maNav = JSON.parse(window.sessionStorage.getItem("maNav"));

		console.log("maNav :  ",maNav );
		if(maNav){ 
			this.setState({Categ: maNav.Categ});
			console.log("Zone300TV did mount manavOK : ",this.state, " maNav : ",maNav.Categ);
		}else{
			this.setState({Categ: 'monZ3'});
		}
		*/

//		console.log("Zone300TV did mount : ",this.state, " maNav : ",maNav.Categ);

/*
		this.setState({isLoaded: false});
		axios.get("https://vod-dev.zone300.com/api/appli" + constants.V_SERVEUR + "/get_Personnalisation.php?NoCli="  + mesData.NoCli)
		.then( res => {
//			console.log("response : ",res.data);
			if(res.data.ret) {
				this.vCateg = res.data.data.categ;
				this.vFilm = res.data.data.films;
				this.setState({isLoaded:true});
			}
		})
		// Charge les données 
*/		
	}
	
	render(){
		/*
		{this.state.Categ === 'monZ3' && <MonZ3  /> }
		{this.state.Categ === 'chasse' && <Chasse /> }
		*/
		const ChildElement = () => {
			switch(this.state.Categ){
				case 'monZ3' : return ( <MonZ3 parent={this} /> ) ; 
				case 'chasse': return ( <Chasse parent={this} /> ); 
				case 'peche' : return ( <Peche parent={this} /> );
				case 'search' : return ( <Search parent={this} /> );
				case 'user' : return ( <MonCompte parent={this} /> );
				default: return ( <MonZ3 parent={this} /> ) ;
			}
		}

		return(
				<div id="container" ref="container" style={{backgroundColor:"#000", width:window.innerWidth, height:window.innerHeight}}>
					<HorizontalList onFocus={(evt,obj) =>{
							this.setState({CategLoaded: false, navig : obj});
							
						}}>
						<SideBar Categ={this.state.Categ} parent={this} />

						<VerticalList focusId="mainBox" >
							{ ChildElement() }
						</VerticalList>
					</HorizontalList>
				</div>
		)
		
	}
}
export default Zone300TV;