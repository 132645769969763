import React from 'react';

import { Focusable, HorizontalList } from 'react-key-navigation';

class ToogleItem extends React.Component {
	constructor() {
		super();

		this.state = {
			active: false
		}
		this.itemWidth = 0;

	}

	onEnterDown(event, navigation, item) {
/*
		console.log('enter pressed');
		console.log("event : ", event);
		console.log("navigation : ", navigation );
		console.log("this.props.film ? ", item);
*/
		window.location.assign('/detail/' + item.transaction);
		navigation.forceFocus('btnFocusableLecture');

//		navigation.forceFocus('sidebar');
	}
	
	render() {

		const affTitre = (this.props.film.aff_titre==='1') ? <div className="TextVignette">{this.props.film.titre}</div> : <div className="TextVignette" >&nbsp;</div>;
		return (
			<Focusable 
				onFocus={() => this.setState({active: true})}
				onBlur={() => this.setState({active: false})}
				onEnterDown={(e, n) => this.onEnterDown(e,n,this.props.film)}
			>
				<div 
					className={'item ' + (this.state.active ? 'item-focus' : '')}
					style={{backgroundSize:'cover',backgroundPosition:'center', backgroundImage: "url(https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + this.props.film.transaction + "-VISUEL.jpg&h=200&crop-to-fit"}}
					
				>
					{affTitre}
				</div>
			</Focusable>
		);
	}
};

export default class List extends React.Component {
  constructor() {
    super();
    this._lastFocus = null;
  }

	componentDidMount() {
		const width = (Math.floor(this.content.scrollWidth /  this.content.clientWidth ) * this.content.clientWidth) + this.content.clientWidth + 20;
		if (this.content.getElementsByClassName('hz-list')[0]) {
			this.content.getElementsByClassName('hz-list')[0].style.width = width + 'px';
		}
	}

	onFocus(index,evt) {
		if (this._lastFocus === index) {
			return;
		}
		if (this.props.onFocus) {
			this.props.onFocus();
		}
		if (this.content) {
			const items = this.content.getElementsByClassName('item');
			const offsetWidth = items[0].offsetWidth + 20;
			this.content.scrollLeft = offsetWidth * index;
		}

		this._lastFocus = index;
	}


	render() {
		return (
			<div className={"contentgroup " + (this.props.visible ? '' : 'fading-out')}>
				<h1>{this.props.title}</h1>
				<div className="content" ref={(content) => { this.content = content}}>
					<HorizontalList className="hz-list"
									style={{overflow: 'hidden', display: 'block'}}
									onFocus={(index,evt) => this.onFocus(index,evt)}
									onBlur={() => { this._lastFocus = null }}
									retainLastFocus={true}
					>
					{this.props.films.map((item,i) =>
						<ToogleItem key={i} film={item} />
					)}
					</HorizontalList>
				</div>
			</div>
		);
	}
}
