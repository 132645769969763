/* eslint no-undef: "off"*/

import React from 'react';
import './App.css';

import Navigation from 'react-key-navigation';

import Nologged from './components/notLogged';
import Browse from './components/browse';

var mData = {
	NoCli: '',
	is_Guest: ''
} 

class App extends React.Component{

	constructor (props){
		super(props)

		this.state = {
			isLoged: false 
		}

		// Detection du device
		if(window.location.pathname==='/' || !localStorage.getItem("device") ){
			console.log("window.location : ",window.location);
			const searchParam = new URLSearchParams(window.location.search);
			try{
				localStorage.setItem("device",searchParam.get("device"));
			}catch{
				//localStorage.removeItem("device");
			}
		}

	}

	
	onKeyDown(evt){
		const preventDefault = function () {
			evt.preventDefault();
			evt.stopPropagation();
			return false;
		};
	
		console.log("KeyCode : ",evt.keyCode);//TODO del
		//TODO décommenter === 8
		if(/*evt.keyCode === 8 || */
			evt.keyCode === 27 || // keyboard esc
			evt.keyCode === 10009 || // Samsung Remote
			evt.keyCode === 461   // LG Magic Remode
		){
			window.history.back();
			return preventDefault();
		} 
	}
	
	componentWillMount(){	

//	componentDidMount(){
		window.addEventListener('keydown', this.onKeyDown);

//		console.log("component did mount ");
		var mesData = localStorage.getItem("LOGIN_TOKEN");
		if(!mesData) mesData = mData;

//		console.log("App => mesData : ",mesData);

		//console.log("this.props.params : ",searchParam.get("device"));

		if(mesData.is_Guest==="" && mesData.NoCli===''){
			this.setState({ isLoged: false })
		}else{
			this.setState({ isLoged: true })
		}

//		console.log("this.state : ",this.state);
	}
	componentWillUnmount(){
		window.removeEventListener('keydown',this.onKeyDown);
	}
	render(){
		return (
			<Navigation>
				{ !this.state.isLoged && <Nologged /> }
				{ this.state.isLoged && <Browse /> }
			</Navigation>
		)
	}
}


export default App;
