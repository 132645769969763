import React from 'react';

/*
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
*/

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import { withFocusable, withNavigation } from 'react-tv-navigation';
import { VerticalList, Focusable } from 'react-key-navigation';

import { Carousel } from "@cybergab/gab-react-responsive-carousel";
import "@cybergab/gab-react-responsive-carousel/lib/styles/carousel.min.css";

import axios from 'axios';
import constants from '../../constants';


const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

const largeur = "100%";
const hauteur = "35vh";

class GBCarousel extends React.Component{
	constructor(props){
		super(props);

		this.state = {
			lastEventType: '',
			imagesExemple: [],
			isLoaded: false,
			myRef: null, 
		}
	}

	_renderMap(myImg,i){
		
//		const MyHauteur = Math.round( (hauteur * 95) / 100);
		
//		const myUri = "https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/img-tv/" + img //+ "&h=" + hauteur/2 + "&w=" + largeur/2 + "&crop-to-fit";
//		console.log("URI : ",myUri);
		/*
		return ( 
			<Focusable key={myImg.transaction}>
				<div style={{width:largeur, height: ( hauteur)}}>
					<img alt='' style={{ width: largeur, height:( hauteur ), alignSelf: 'center'}} src={"https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + myImg.transaction + "-VISUEL3.jpg&h=720&crop-to-fit"} />
				</div>
			</Focusable>
		)
		*/
		return ( 
				<div key={ myImg.transaction } style={{width:largeur, height: ( hauteur)}}>
					<img alt='' style={{ width: largeur, height:( hauteur ), alignSelf: 'center'}} src={"https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/" + myImg.transaction + "-VISUEL3.jpg&h=720&crop-to-fit"} />
					{ myImg.aff_titre === '1' &&
					<div>
						{myImg.titre}
					</div>
					}
				</div>
		)
	}

	componentWillMount(){
		axios.get("https://vod-dev.zone300.com/api/appli" + constants.V_SERVEUR + "/getCarousel.php?Categ=" + this.props.theme)
		.then( res => {
			//console.log("res : ",res);
			const mesData = res.data;
			if(mesData.ret){
				this.setState({imagesExemple: mesData.data, isLoaded: true});
				//console.log("this.state : ",this.state)

			}
		})
		if(this.monCar) this.setState({MyRef: this.monCar})
	}

	render(){
/*
				<Carousel 
					responsive={responsive}
					showDots={true}
					dotListClass="custom-dot-list-style"
				>
					{ this.state.imagesExemple.map( (img) => this._renderMap(img) ) }
				</Carousel>
*/
		return (
			<div style={{width: largeur, height: hauteur}}>
			{ this.state.isLoaded && 
					<Carousel {...this.props}
						ref="MyCar1"
						onClickItem={(e,obj)=>{console.log("ClickItem e : ",e," obj : ",obj); window.location.assign('/detail/' + obj.key);}}
					>
						{ this.state.imagesExemple.map( (img,i) => this._renderMap(img,i) ) }
					</Carousel>

			}
			{ !this.state.isLoaded && 
				<div style={{width:"100%", height: "100%", textAlign: 'center', justifyContent: 'center', marginTop:'10vh'}}>
					<Spinner  color="#FBA00B" size={52} speed={0.7} animating={true} />

				</div> 
			}

			</div>

		)
	}
}
export default GBCarousel